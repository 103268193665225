<template>
  <div class="grid">
    <div class="col-12">
      <Card>
        <template #title>Declaração de Rendimentos</template>
        <template #content>
          <DataTable
            stripedRows
            :paginator="true"
            :value="rendimentosList"
            :loading="loading"
            :rows="5"
            :filters.sync="filtros"
            :globalFilterFields="['titulo', 'data']"
            filterDisplay="menu"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="rowsPerPageOptions"
            currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} contracheque"
            responsiveLayout="stack">
            <template #empty> Nenhum contracheque encontrado. </template>
            <template #loading> Carregando. Por favor aguarde. </template>
            <template #header>
              <div class="flex flex-column sm:flex-row">
                <span class="p-input-icon-left mb-2 mr-2">
                  <i class="pi pi-search" />
                  <InputText
                    v-model="filtros['global'].value"
                    placeholder="Pesquisar"
                    style="width: 100%" />
                </span>
                <Button
                  type="button"
                  icon="pi pi-filter-slash"
                  label="Limpar"
                  class="p-button-outlined mb-2"
                  @click="limparFiltro" />
              </div>
            </template>
            <Column :sortable="true" field="titulo" header="Título"></Column>
            <Column field="data" header="Competencia">
              <template #body="slotProps">
                <div v-if="slotProps.data.data != null">
                  {{ slotProps.data.data }}
                </div>
                <div v-else>
                  {{ slotProps.data.periodo }}
                </div>
              </template>
            </Column>
            <Column header="Visualizar">
              <template #body="{ data }">
                <div>
                  <Button
                    v-tooltip.left="'Visualizar Rendimento'"
                    :placeholder="'Visualizar Rendimento'"
                    icon="pi pi-file-pdf"
                    class="p-button-rounded p-button-success mr-2"
                    @click="gerarRendimentosPDF(data)" />
                </div>
              </template>
            </Column>
          </DataTable>
        </template>
      </Card>
    </div>
  </div>
</template>
<script>
import { saveAs } from 'file-saver'
import { FilterMatchMode, FilterOperator } from 'primevue/api/'
import MatriculaService from '@/service/MatriculaService'
import ContrachequeService from '@/service/ContrachequeService'
import { selecionarObjetoPadrao } from '@/utils/SelecionarObjetoPadrao'
import { matriculaStore } from '@/stores/matricula'

export default {
  setup() {
    const store = new matriculaStore()
    return { store }
  },

  data() {
    return {
      matriculaValue: ``,
      matriculasList: [],
      rendimentosList: [],
      loading: false,
      filtros: {},
    }
  },

  computed: {
    rowsPerPageOptions() {
      return [5, 10, 25]
    },
  },

  watch: {
    'store.matriculaSelecionada': function (novaMatricula) {
      this.carregarDados(novaMatricula.id)
    },
  },

  created() {
    this.loading = true
    this.matriculaService = new MatriculaService(this.$http)
    this.contrachequeService = new ContrachequeService(this.$http)
    this.initFiltros()
    if (
      this.store.matriculaSelecionada !== null &&
      this.store.matriculaSelecionada !== undefined
    ) {
      this.carregarDados(this.store.matriculaSelecionada.id)
    }
  },

  mounted() {
    this.carregarMatriculas()
  },

  methods: {
    limparFiltro() {
      this.filtros['global'].value = ''
    },

    initFiltros() {
      this.filtros = {
        global: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.CONTAINS },
            { value: null, matchMode: FilterMatchMode.EQUALS },
          ],
        },
      }
    },

    carregarMatriculas() {
      this.matriculaService
        .getMatriculasUsuarioLogado()
        .then((res) => {
          this.matriculasList = res
          for (const idx in this.matriculasList) {
            this.matriculasList[idx].key = parseInt(idx)
          }
          this.matriculaValue = selecionarObjetoPadrao(this.matriculasList)
          if (this.matriculaValue != null) {
            this.carregarDados(this.matriculaValue.id)
          }
          this.loading = false
        })
        .catch((err) => {
          this.showToastError(err)
        })
    },

    carregarDados(matriculaId) {
      this.contrachequeService
        .getListaInformesRendimentoDoServidor(matriculaId)
        .then((res) => {
          this.rendimentosList = res
          this.loading = false
        })
        .catch((err) => {
          this.showToastError(err)
        })
    },

    gerarRendimentosPDF(data) {
      let map = new Map()
      map.set('filename', data.filename)
      map.set('matricula', data.matricula)
      map.set('folha', data.folha)
      map.set('exercicio', data.periodo)
      map.set('matriculaId', this.store.matriculaSelecionada.id)
      this.contrachequeService
        .downloadInformeRendimento(map)
        .then((res) => {
          this.downloadContracheque(res, data)
        })
        .catch((err) => {
          this.showToastError(err)
        })
    },

    downloadContracheque(response, data) {
      const blob = new Blob([response], {
        type: 'application/pdf',
      })
      if (data.data == null) {
        saveAs(
          blob,
          `${data.titulo}_CPF_${this.store.matriculaSelecionada.cpf}.pdf`,
        )
      } else {
        saveAs(blob, `${data.titulo}_${data.data}.pdf`)
      }
    },

    showToastError(err) {
      const mensagem = err.response
        ? err.response.data.message
        : 'Erro de conexão com a aplicação.'
      this.$toast.add({
        severity: 'error',
        summary: mensagem,
        life: 10000,
      })
    },

    downloadRelatorio(contentyType, nomeRelatorio, response) {
      if ('PDF' === contentyType) {
        const blob = new Blob([response], {
          type: 'application/pdf',
        })
        var fileURL = URL.createObjectURL(blob)
        window.open(fileURL)
      }
    },
  },
}
</script>
<style>
.card-mobile {
  display: none;
}

.card-desktop {
  visibility: block;
}

@media only screen and (max-width: 600px) {
  .card-mobile {
    display: block;
  }

  .card-desktop {
    display: none;
  }
}
</style>
